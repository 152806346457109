var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f4c1f5a014e5ff7314b0388306a79545902993d0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,

  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [process.env.NEXT_PUBLIC_BASE_URL],
    }),
  ],

  environment: process.env.NEXT_PUBLIC_APP_ENV,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.25,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (process.env.NODE_ENV === 'development') {
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id })
      }
    }
    return event
  },
})
